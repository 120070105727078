import { render, staticRenderFns } from "./ChordControl.vue?vue&type=template&id=cd790f9a&scoped=true&"
import script from "./ChordControl.vue?vue&type=script&lang=js&"
export * from "./ChordControl.vue?vue&type=script&lang=js&"
import style0 from "./ChordControl.vue?vue&type=style&index=0&id=cd790f9a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd790f9a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VCard,VCardActions,VIcon,VItemGroup,VNavigationDrawer})
