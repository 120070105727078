var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit Section")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Note name","maxlength":30,"counter":"","hint":_vm.warningFig,"persistent-hint":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
var key = ref.key;
return [_c('div',{class:key === 0 ? 'error--text' : ''},[_vm._v(" "+_vm._s(message)+" ")])]}}]),model:{value:(_vm.editedName),callback:function ($$v) {_vm.editedName=$$v},expression:"editedName"}})],1),_c('v-col',{attrs:{"cols":"auto","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Shortcode","maxlength":"3","counter":"","hint":_vm.warningCode,"persistent-hint":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
var key = ref.key;
return [_c('div',{class:key === 0 ? 'error--text' : ''},[_vm._v(" "+_vm._s(message)+" ")])]}}]),model:{value:(_vm.editedCode),callback:function ($$v) {_vm.editedCode=$$v},expression:"editedCode"}})],1),(_vm.sectionID != 0)?_c('v-col',{attrs:{"cols":"auto","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.parentOptions,"item-text":"code","item-value":"id","label":"Parent Section"},model:{value:(_vm.editedParentID),callback:function ($$v) {_vm.editedParentID=$$v},expression:"editedParentID"}})],1):_vm._e(),(Array.isArray(_vm.editedRTP))?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"auto","sm":"6","md":"4"}},[_c('InputRatio',{attrs:{"label":"Ratio to Parent","ratio":_vm.editedRTP,"min":1,"max":999999}})],1):(!isNaN(_vm.editedRTP))?_c('v-col',{attrs:{"cols":"auto","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Ratio to Parent","max":"2400","min":"-2400","suffix":"¢"},model:{value:(_vm.editedRTP),callback:function ($$v) {_vm.editedRTP=$$v},expression:"editedRTP"}})],1):_vm._e(),(_vm.sectionID != 0)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.ratioViewMode != 1),expression:"ratioViewMode != 1"}],staticClass:"my-0 py-0",attrs:{"cols":"auto","sm":"6","md":"4"}},[(_vm.ratioViewMode == 0)?_c('InputRatio',{attrs:{"label":"Temperament","ratio":_vm.editedTemperament,"min":-999999,"max":999999,"hint":_vm.tempView}}):_c('v-text-field',{staticClass:"my-4",attrs:{"label":"Temperament","suffix":"¢"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
var key = ref.key;
return [_c('div',{class:key === 0 ? 'error--text' : ''},[_vm._v(" "+_vm._s(message)+" ")])]}}],null,false,3096540738),model:{value:(_vm.editedTemperament),callback:function ($$v) {_vm.editedTemperament=$$v},expression:"editedTemperament"}})],1):_vm._e(),(_vm.sectionID == 0)?_c('v-col',{attrs:{"cols":"auto","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"my-4",attrs:{"label":"Frequency","suffix":"Hz","min":"20","max":"4000"},model:{value:(_vm.editedFreq),callback:function ($$v) {_vm.editedFreq=$$v},expression:"editedFreq"}})],1):_vm._e(),(_vm.sectionID == 0)?_c('v-col',{attrs:{"cols":"auto","sm":"6","md":"4"}},[(_vm.sectionID == 0)?_c('v-text-field',{staticClass:"my-4",attrs:{"label":"String Length","suffix":"units","min":"1","max":"120000"},model:{value:(_vm.editedStrL),callback:function ($$v) {_vm.editedStrL=$$v},expression:"editedStrL"}}):_vm._e()],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":_vm.invalid,"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();_vm.saveSection();
          _vm.dialog = false;}}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }